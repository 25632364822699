export const permissionMenu = [
  {
    id: 1,
    menuName: "Overview",
    url: "/dashboard/scopedprojects",
    isParentRoute: true,
    permissions: {
      table: ["tableoverviewDashboard"],
      tab: [
        "tabscopedprojects",
        "tabprojectinproposal",
        "tabdealreview",
        "tablegal",
        "tabresourcemanagement",
        "tabfinance",
        "tabhistory",
      ],
      card: [
        "cardscoped",
        "cardinproposal",
        "carddealreview",
        "cardlegalreview",
        "cardresourcemanagement",
      ],
      button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
    },
    otherRoutes: {
      "/dashboard/projectinproposal": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/dealreview": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/legal": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/resourcemanagement": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/finance": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/history": {
        permissions: {
          table: ["tableoverviewDashboard"],
          tab: [
            "tabscopedprojects",
            "tabprojectinproposal",
            "tabdealreview",
            "tablegal",
            "tabresourcemanagement",
            "tabfinance",
            "tabhistory",
          ],
          card: [
            "cardscoped",
            "cardinproposal",
            "carddealreview",
            "cardlegalreview",
            "cardresourcemanagement",
          ],
          button: ["btnrefresh", "btnexportbutton", "btndownloadIcon"],
        },
      },
      "/dashboard/scoped-overview/new": {
        permissions: {
          card: [
            "cardprojectoverview",
            "cardprojectstatus",
            "cardprojectdescription",
            "cardadditionalinfo",
            "cardeditnotes",
          ],
          table: [],
          tab: [],
          button: [
            "btnbeginpreliminarystaffing",
            "btnsavebutton",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddinfoeditpracticelead",
            "btnaddinfoeditcompetencylead",
          ],
        },
      },
      "/dashboard/scoped-review/in-progress": {
        permissions: {
          card: ["cardprojectdetail", "cardnotes"],
          table: ["tablepreliminarytable"],
          tab: [],
          button: [
            "btnfloatingbutton",
            "btnsavebutton",
            "btnsubmitButton",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
          ],
          // Default button permissions
          // "btnmodalconfirmbutton", "btnmodalcancelbutton"
        },
      },
      "/dashboard/scoped-overview/completed": {
        permissions: {
          card: [
            "cardprojectoverview",
            "cardprojectstatus",
            "cardprojectdescription",
            "cardadditionalinfo",
            "cardeditnotes",
            "cardeditrmnotes",
          ],
          table: ["tablepreliminarystaffing"],
          tab: [],
          button: ["btnopenpreliminarystaffingtable"],
        },
      },
      "/dashboard/over-view-project/proposal/dealreview/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealoverviewpage",
            "cardstatusbarcardindealoverviewpage",
            "carddealreviewoverviewprojectdescriptioncontainor",
            "carddealreviewoverviewadditionalinfocontainor",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: [
            "btnbegindealreview",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
          ],
        },
      },
      "/dashboard/over-view-project/proposal/dealreview/resources": {
        permissions: {
          card: [],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: ["btnbegindealreview"],
        },
      },
      "/dashboard/over-view-project/proposal/projectinproposal/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealoverviewpage",
            "cardstatusbarcardindealoverviewpage",
            "carddealreviewoverviewprojectdescriptioncontainor",
            "carddealreviewoverviewadditionalinfocontainor",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: [
            "btnbegindealreview",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
          ],
        },
      },
      "/dashboard/over-view-project/proposal/projectinproposal/resources": {
        permissions: {
          card: [],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: ["btnbegindealreview"],
        },
      },
      "/dashboard/deal-review/in-progress/dealreview/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardwhichhindofprojectcontainer",
            "cardprojectdateselectcontainer",
            "cardservicecategoriescontainer",
            "cardlifecyclecontainer",
            "cardpricingtypecontainer",
            "cardnotescontainer",
            "cardtechnologycontainer",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: ["btnsavechangesindealreviewreview", "btnclientmanaged"],
        },
      },
      "/dashboard/deal-review/in-progress/dealreview/staffing": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardhighlightcardindealreviewstaffing",
            "cardnotesforusersindealreview",
          ],
          table: ["tableroletableindealreview"],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsavechangesindealreviewreview",
            "btnpopupopenclicklink",
            "btncancelpopupbutton",
            "btnupdatepopupbutton",
          ],
        },
      },
      "/dashboard/deal-review/in-progress/dealreview/staffingintake": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardrolerequirementcontainer",
            "cardclientinterviewcontainer",
            "cardstaffingtyperesource",
            "cardrequiredskillcontainer",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsavechangesindealreviewreview",
            "btnsubmitbutton",
            "btnhaveclientinterview",
            "btnlaptopneededswitch"
          ],
        },
      },
      "/dashboard/deal-review/in-progress/dealreview/review": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardhighlightcardindealreviewreview",
            "cardlistcardindealreviewreview",
          ],
          table: ["tablestaffingtableindealreviewreview"],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsubmitforreview",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
      "/dashboard/over-view-project/reopen/dealreview/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealoverviewpage",
            "cardstatusbarcardindealoverviewpage",
            "carddealreviewoverviewprojectdescriptioncontainor",
            "carddealreviewoverviewadditionalinfocontainor",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: [
            "btnbegindealreview",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
            "btnsubmitforreview",
          ],
        },
      },
      "/dashboard/over-view-project/reopen/dealreview/resources" : {
                permissions: {
                    card: [],
                    table: [],
                    tab: ["taboverview", "tabresources"],
                    button: ["btnbegindealreview"]
                },
      },
      "/dashboard/review-and-approval/in-review": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewinreview",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          // All permissions are Dafault card permission
          table: ["tableresourcestable", "tablestaffingintakereviewtable"],
          // All permissions are Dafault table permission
          tab: ["taboverview", "tabmarginsstaffing", "tabstaffingintakerm"],

          button: [
            "btnapproverejectbuttoncontainer",
            "btnlegalreject",
            "btnlegalapprove",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnclientmanagedswitch",
          ],
          // Default button permissions
          // "btnapproverejectbuttoncontainer", "btnmodalcancelbutton","btnmodalconfirmbutton"
        },
      },
      "/dashboard/review-and-approval/approved": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewinreview",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          table: ["tableresourcestable", "tablestaffingintakereviewtable"],
          tab: ["taboverview", "tabmarginsstaffing", "tabstaffingintakerm"],
          button: ["btnsendtolegalcontainer", "btnsubmitlegal", "btnclientmanagedswitch"],
        },
      },
      "/dashboard/legal-questionnaire/pending": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewquestionary",
            "carddatescontainer",
            "cardadditionalinformation",
            "cardclientresponsibilityinquestionary",
            "cardassumptionvaluesinquestionary",
            "carddealdescriptioninquestionary",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsubmitbutton",
            "btnsavebutton",
            "btnsaveandsubmitcontainer",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
            "btnisagile",
            "btndeliverablesset",
            "btniscapped",
            "btnissupportincluded",
          ],
        },
      },
      "/dashboard/legal-over-view/new/legal/overview": {
        // Legal new Overview
        permissions: {
          card: [
            "cardlegaloverviewprojectcard",
            "cardlegaloverviewprojectstatus",
            "cardadditionalinfo",
            "carddealdescription",
            "cardprojectdetails",
          ],
          table: [],
          tab: ["taboverview", "tabdealreview"],
          button: ["btnbeginlegalreview"],
        },
      },
      "/dashboard/legal-over-view/new/legal/dealreview": {
        // Legal new Deal Review Overview
        permissions: {
          card: [
            "cardlegaldealreviewprojectcard",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          table: ["tableresourcestable"],
          tab: [
            "taboverview",
            "tabdealreview",
            "tablegaloverviewoverview",
            "tablegaloverviewmarginsstaffing",
          ],
          button: ["btnclientmanagedswitch", "btnbeginlegalreview"],
        },
      },
      "/dashboard/sow-generation/in-progress/legal/sowinfo": {
        // SOW generation
        permissions: {
          card: [
            "cardgeneratesowprojectoverview",
            "carddeleverablesList",
            "cardprojectassumptions",
            "cardclientresponsibility",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btndealdescriptioneditIcon",
            "btnassumptionseditIcon",
            "btnresponsibilityeditIcon",
            "btnsavechanges",
          ],
        },
      },
      "/dashboard/sow-generation/in-progress/legal/generatesow": {
        // SOW generation
        permissions: {
          card: [
            "cardgeneratesowprojectoverview",
            "cardautomatedsowgeneration",
            "cardselectsowtemplate",
            "cardcontainersow",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btngeneratesowbutton",
            "btndownloadsowbutton",
            "btnsendtovalidation",
          ],
        },
      },
      "/dashboard/sow-upload/in-validation/legal": {
        // SOW upload in-validation
        permissions: {
          card: [
            "cardlegaloverviewprojectcard",
            "cardchoosegeneratedsow",
            "cardchooseuploadsow",
            "carduploadsow",
            "cardgeneratedsow",
            "carduploadedsow",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btnuploadsowbutton",
            "btnsendtoreview",
            "btnuploadedpreview",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
      "/dashboard/sow-review/in-review/legal": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
          ],
        },
      },
      "/dashboard/sow-review/customer-review/legal": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
          ],
        },
      },
      "/dashboard/sow-review/rejected/legal": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
            "cardreasoncomments",
          ],
          table: [],
          tab: [],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
            "btndeallostbutton",
            "btnreopendealbutton",
            "btnreopenlegalbutton",
            "btnfloatingbuttons",
          ],
        },
      },
      "/dashboard/sow-review/approved/legal": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
            "cardsowupload",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
            "btnsendresourcetorm",
          ],
        },
      },
      "/dashboard/resource-allocation-dashboard/resource-allocation/proposal": {
        permissions: {
          card: [
            "cardprojectdescription",
            "cardoverviewcardinrmresourceallocation",
            "carddetailscontainer",
            "cardviewsow",
          ],
          table: ["tableresourcerequested"],
          tab: [],
          button: ["btnbeginra", "btnview"],
        },
      },
      "/dashboard/resource-allocation/resource-allocation-fact/review/inprogress":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: [
              "btnsubmitreview",
              "btnmodalconfirmbutton",
              "btnmodalcancelbutton",
            ],
          },
        },
      "/dashboard/resource-allocation/resource-allocation-fact": {
        permissions: {
          card: ["cardprojectoverview"],
          table: [],
          tab: [],
          button: [
            "btnreview",
            "btnsave",
            "btnshowonlyavailableresources",
            "btnautorecommend",
          ],
        },
      },
      "/dashboard/resource-allocation/resource-allocation-fact/review/in-review":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: [
              "btnreject",
              "btnapprove",
              "btnmodalconfirmbutton",
              "btnmodalcancelbutton",
            ],
          },
        },
      "/dashboard/resource-allocation/resource-allocation-fact/review/reopen": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btnreturndashboard"],
        },
      }, // start from this
      "/dashboard/resource-allocation/resource-allocation-fact/review/approved":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: ["btncomplete"],
          },
        },
      "/dashboard/resource-allocation/resource-allocation-fact/review/completed":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: ["btnreturndashboard"],
          },
        },
    },
  },
  {
    id: 2,
    menuName: "Scoped Deals",
    url: "/scoped-dashboard",
    isParentRoute: true,
    permissions: {
      card: [],
      table: ["tablescopeddashboard","tableforcastprobability"],
      tab: ["tabscopedprojects"],
      button: ["btnexportbutton", "btndownloadIcon", "btnrefresh","btnforcastapply","btnforcastinsert"],
    },
    otherRoutes: {
      "/scoped-dashboard/scoped-overview/new": {
        permissions: {
          card: [
            "cardprojectoverview",
            "cardprojectstatus",
            "cardprojectdescription",
            "cardadditionalinfo",
            "cardeditnotes",
          ],
          table: [],
          tab: [],
          button: [
            "btnbeginpreliminarystaffing",
            "btnsavebutton",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
            "btnrefresh"
          ],
        },
      },
      "/scoped-dashboard/scoped-review/in-progress": {
        permissions: {
          card: ["cardprojectdetail", "cardnotes"],
          table: ["tablepreliminarytable"],
          tab: [],
          button: [
            "btnfloatingbutton",
            "btnsavebutton",
            "btnsubmitButton",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
            "btnrefresh"
          ],
        },
      },
      "/scoped-dashboard/scoped-overview/completed": {
        permissions: {
          card: [
            "cardprojectoverview",
            "cardprojectstatus",
            "cardprojectdescription",
            "cardadditionalinfo",
            "cardeditnotes",
            "cardeditrmnotes",
            "btnrefresh"
          ],
          table: ["tablepreliminarystaffing"],
          tab: [],
          button: ["btnopenpreliminarystaffingtable"],
        },
      },
    },
  },
  {
    id: 3,
    menuName: "INAB",
    url: "/inab/new",
    isParentRoute: true,
    permissions: {
      card: [
        "cardnewsubmissions",
        "cardprojectsinflight",
        "cardprojectscompleted",
      ],
      table: ["tableinabdashboard"],
      tab: [
        "tabnew",
        "tabprojectsinflight",
        "tabapproved",
        "tabcompleted",
        "tabcancelledrejected",
      ],
      button: ["btnnewsubmissionbutton", "btnexportbutton", "btndownloadIcon"],
    },
    otherRoutes: {
      "/inab/projectsinflight": {
        permissions: {
          card: [
            "cardnewsubmissions",
            "cardprojectsinflight",
            "cardprojectscompleted",
          ],
          table: ["tableinabdashboard"],
          tab: [
            "tabnew",
            "tabprojectsinflight",
            "tabapproved",
            "tabcompleted",
            "tabcancelledrejected",
          ],
          button: [
            "btnnewsubmissionbutton",
            "btndownloadIcon",
            "btnexportbutton",
          ],
        },
      },
      "/inab/approved": {
        permissions: {
          card: [
            "cardnewsubmissions",
            "cardprojectsinflight",
            "cardprojectscompleted",
          ],
          table: ["tableinabdashboard"],
          tab: [
            "tabnew",
            "tabprojectsinflight",
            "tabapproved",
            "tabcompleted",
            "tabcancelledrejected",
          ],
          button: [
            "btnnewsubmissionbutton",
            "btndownloadIcon",
            "btnexportbutton",
          ],
        },
      },
      "/inab/completed": {
        permissions: {
          card: [
            "cardnewsubmissions",
            "cardprojectsinflight",
            "cardprojectscompleted",
          ],
          table: ["tableinabdashboard"],
          tab: [
            "tabnew",
            "tabprojectsinflight",
            "tabapproved",
            "tabcompleted",
            "tabcancelledrejected",
          ],
          button: [
            "btnnewsubmissionbutton",
            "btndownloadIcon",
            "btnexportbutton",
          ],
        },
      },
      "/inab/cancelledrejected": {
        permissions: {
          card: [
            "cardnewsubmissions",
            "cardprojectsinflight",
            "cardprojectscompleted",
          ],
          table: ["tableinabdashboard"],
          tab: [
            "tabnew",
            "tabprojectsinflight",
            "tabapproved",
            "tabcompleted",
            "tabcancelledrejected",
          ],
          button: [
            "btnnewsubmissionbutton",
            "btndownloadIcon",
            "btnexportbutton",
          ],
        },
      },
      "/inab/new-submission": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: ["tableinabdashboard"],
          button: [
            "btninabnewpagefloatingcontainer",
            "btnfloatingbuttoncancel",
            "btnfloatingbuttonsubmit",
          ],
        },
      },
      "/inab/review-and-approval/proposal": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [
            "btninabapprovepagefloatingcontainer",
            "btnfloatingbuttonreject",
            "btnfloatingbuttonapprove",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
      "/inab/submit-for-rm/approved": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [
            "btnfloatingbuttonsendtoresourcemanagement",
            "btninabsubmitpagefloatingcontainer",
          ],
        },
      },
      "/inab/submit-for-rm/completed": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
      "/inab/submit-for-rm/rejected": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
    },
  },
  {
    id: 4,
    menuName: "Deal Review",
    url: "/deal-review-dashboard/new",
    isParentRoute: true,
    permissions: {
      card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
      table: ["tabledealreviewdashboard"],
      tab: [
        "tabnew",
        "tabinprogress",
        "tabreopen",
        "tabinreview",
        "tabapproved",
        "tablegalpending",
        "tabcompleted",
      ],
      button: ["btnexportbutton", "download-button", "btndownloadIcon"],
    },
    otherRoutes: {
      "/deal-review-dashboard/inprogress": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/legalpending": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/approved": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/inreview": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/reopen": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/completed": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ["tabledealreviewdashboard"],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabinreview",
            "tabapproved",
            "tablegalpending",
            "tabcompleted",
          ],
          button: ["btndownloadIcon", "btnexportbutton"],
        },
      },
      "/deal-review-dashboard/over-view-project/proposal/new/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealoverviewpage",
            "cardstatusbarcardindealoverviewpage",
            "carddealreviewoverviewprojectdescriptioncontainor",
            "carddealreviewoverviewadditionalinfocontainor",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: [
            "btnbegindealreview",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
            "btnsubmitforreview",
          ],
        },
      },
      "/deal-review-dashboard/deal-review/in-progress/inprogress/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardwhichhindofprojectcontainer",
            "cardprojectdateselectcontainer",
            "cardservicecategoriescontainer",
            "cardlifecyclecontainer",
            "cardpricingtypecontainer",
            "cardnotescontainer",
            "cardtechnologycontainer",
            "cardhighlightcardindealreviewstaffing",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: ["btnsavechangesindealreviewreview", "btnclientmanaged"],
        },
      },
      "/deal-review-dashboard/deal-review/in-progress/inprogress/staffing": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardhighlightcardindealreviewstaffing",
            "cardnotesforusersindealreview",
          ],
          table: ["tableroletableindealreview"],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsavechangesindealreviewreview",
            "btnpopupopenclicklink",
            "btncancelpopupbutton",
            "btnupdatepopupbutton",
            "btnsubmitforreview",
          ],
        },
      },
      "/deal-review-dashboard/deal-review/in-progress/inprogress/staffingintake":
        {
          permissions: {
            card: [
              "cardoverviewcardindealreviewreviewpage",
              "cardrolerequirementcontainer",
              "cardclientinterviewcontainer",
              "cardstaffingtyperesource",
              "cardrequiredskillcontainer",
              "cardoverviewIndealreviewreviewtab",
            ],
            table: ["tableroletableindealreview"],
            tab: [
              "taboverview",
              "tabstaffing",
              "tabstaffingintake",
              "tabreview",
            ],
            button: [
              "btnsavechangesindealreviewreview",
              "btnsubmitbutton",
              "btnsubmitforreview",
              "btnhaveclientinterview",
              "btnlaptopneededswitch"
            ],
          },
        },
      "/deal-review-dashboard/deal-review/in-progress/inprogress/review": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardoverviewIndealreviewreviewtab",
            "cardhighlightcardindealreviewreview",
            "cardlistcardindealreviewreview",
          ],
          table: ["tablestaffingtableindealreviewreview"],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsavechangesindealreviewreview",
            "btnsubmitbutton",
            "btnsubmitforreview",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
      "/deal-review-dashboard/review-and-approval/in-review": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewinreview",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          table: ["tableresourcestable", "tablestaffingintakereviewtable"],
          tab: ["taboverview", "tabmarginsstaffing", "tabstaffingintakerm"],
          button: [
            "btnapproverejectbuttoncontainer",
            "btnlegalreject",
            "btnlegalapprove",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnclientmanagedswitch"
          ],
        },
      },
      "/deal-review-dashboard/review-and-approval/approved": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewinreview",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          table: ["tableresourcestable", "tablestaffingintakereviewtable"],
          tab: ["taboverview", "tabmarginsstaffing", "tabstaffingintakerm"],
          button: ["btnsendtolegalcontainer", "btnsubmitlegal", "btnclientmanagedswitch"],
        },
      },
      "/deal-review-dashboard/over-view-project/reopen/reopen/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealoverviewpage",
            "cardstatusbarcardindealoverviewpage",
            "carddealreviewoverviewprojectdescriptioncontainor",
            "carddealreviewoverviewadditionalinfocontainor",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: [
            "btnbegindealreview",
            "btnaddInfoeditengagementmanager",
            "btnaddInfoeditdeliverymanager",
            "btnaddInfoeditpracticelead",
            "btnaddInfoeditcompetencylead",
            "btnsubmitforreview",
          ],
        },
      },
      "/deal-review-dashboard/deal-review/in-progress/overview/overview": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewreviewpage",
            "cardwhichhindofprojectcontainer",
            "cardprojectdateselectcontainer",
            "cardservicecategoriescontainer",
            "cardlifecyclecontainer",
            "cardpricingtypecontainer",
            "cardnotescontainer",
            "cardtechnologycontainer",
            "cardhighlightcardindealreviewstaffing",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: ["btnsavechangesindealreviewreview", "btnsubmitforreview"],
        },
      },
      "/deal-review-dashboard/legal-questionnaire/pending": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewquestionary",
            "carddatescontainer",
            "cardadditionalinformation",
            "cardclientresponsibilityinquestionary",
            "cardassumptionvaluesinquestionary",
            "carddealdescriptioninquestionary",
          ],
          table: [],
          tab: ["taboverview", "tabstaffing", "tabstaffingintake", "tabreview"],
          button: [
            "btnsubmitbutton",
            "btnsavebutton",
            "btnsaveandsubmitcontainer",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
            "btnisagile",
            "btndeliverablesset",
            "btniscapped",
            "btnissupportincluded",
          ],
        },
      },
      "/deal-review-dashboard/review-and-approval/completed": {
        permissions: {
          card: [
            "cardoverviewcardindealreviewinreview",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "cardnotescontainer",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
            'carddealreviewnotes',
          ],
          table: [
            "tablestaffingintakereviewtable",
            "tableresourcestable",
          ],
          tab: ["taboverview", "tabmarginsstaffing", "tabstaffingintakerm"],
          button: [
            "btnlegalbacktodashboard",
            "btnbacktodashboardbuttoncontainer",
            "btnsenttolegalbuttoncontainer",
            "btnapproverejectbuttoncontainer",
            "btnlegalreject",
            "btnsendtolegal",
            "btnlegalapprove",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
    },
  },
  {
    id: 5,
    menuName: "Legal Review",
    url: "/legal-dashboard/new",
    isParentRoute: true,
    permissions: {
      card: [
        "cardnew",
        "cardinprogress",
        "cardvalidation",
        "cardinreview",
        "cardcomplete",
      ],
      table: ['tablelegalDashboard'],
      tab: [
        "tabnew",
        "tabinprogress",
        "tabvalidation",
        "tabinreview",
        "tabcompleted",
      ],
      button: ["btnexportbutton", "btndownloadIcon"],
    },
    otherRoutes: {
      "/legal-dashboard/inprogress": {
        permissions: {
          card: [
            "cardnew",
            "cardinprogress",
            "cardvalidation",
            "cardinreview",
            "cardcomplete",
          ],
          table: ['tablelegalDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabvalidation",
            "tabinreview",
            "tabcompleted",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/legal-dashboard/validation": {
        permissions: {
          card: [
            "cardnew",
            "cardinprogress",
            "cardvalidation",
            "cardinreview",
            "cardcomplete",
          ],
          table: ['tablelegalDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabvalidation",
            "tabinreview",
            "tabcompleted",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/legal-dashboard/inreview": {
        permissions: {
          card: [
            "cardnew",
            "cardinprogress",
            "cardvalidation",
            "cardinreview",
            "cardcomplete",
          ],
          table: ['tablelegalDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabvalidation",
            "tabinreview",
            "tabcompleted",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/legal-dashboard/completed": {
        permissions: {
          card: [
            "cardnew",
            "cardinprogress",
            "cardvalidation",
            "cardinreview",
            "cardcomplete",
          ],
          table: ['tablelegalDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabvalidation",
            "tabinreview",
            "tabcompleted",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/legal-dashboard/legal-over-view/new/new/overview": {
        permissions: {
          card: [
            "cardlegaloverviewprojectcard",
            "cardlegaloverviewprojectstatus",
            "cardadditionalinfo",
            "carddealdescription",
            "cardprojectdetails",
          ],
          table: [],
          tab: ["taboverview", "tabdealreview"],
          button: ["btnbeginlegalreview"],
        },
      },
      "/legal-dashboard/legal-over-view/new/new/dealreview": {
        permissions: {
          card: [
            "cardlegaldealreviewprojectcard",
            "carddealreviewprojectdetails",
            "carddealreviewmargindetails",
            "cardclientmanageddiscription",
            "carddealreviewnotes",
            "cardrevenuemargins",
            "cardmarginsstaffingcalc",
          ],
          table: ["tableresourcestable"],
          tab: [
            "taboverview",
            "tabdealreview",
            "tablegaloverviewoverview",
            "tablegaloverviewmarginsstaffing",
          ],
          button: ["btnclientmanagedswitch", "btnbeginlegalreview"],
        },
      },
      "/legal-dashboard/sow-generation/in-progress/inprogress/sowinfo": {
        permissions: {
          card: [
            "cardgeneratesowprojectoverview",
            "carddeleverablesList",
            "cardprojectassumptions",
            "cardclientresponsibility",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btndealdescriptioneditIcon",
            "btnassumptionseditIcon",
            "btnresponsibilityeditIcon",
            "btnsavechanges",
          ],
        },
      },
      "/legal-dashboard/sow-generation/in-progress/inprogress/generatesow": {
        permissions: {
          card: [
            "cardgeneratesowprojectoverview",
            "cardautomatedsowgeneration",
            "cardselectsowtemplate",
            "cardcontainersow",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btngeneratesowbutton",
            "btndownloadsowbutton",
            "btnsendtovalidation",
          ],
        },
      },
      "/legal-dashboard/sow-upload/in-validation/validation": {
        permissions: {
          card: [
            "cardlegaloverviewprojectcard",
            "cardchoosegeneratedsow",
            "cardchooseuploadsow",
            "carduploadsow",
            "cardgeneratedsow",
            "carduploadedsow",
          ],
          table: [],
          tab: ["tabsowinfo", "tabgeneratesow"],
          button: [
            "btnuploadsowbutton",
            "btnsendtoreview",
            "btnuploadedpreview",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
          ],
        },
      },
      "/legal-dashboard/sow-review/in-review/inreview": {
        // SOW upload in-validation
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
          ],
        },
      },
      "/legal-dashboard/sow-review/customer-review/inreview": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
          ],
        },
      },
      "/legal-dashboard/sow-review/approved/inreview": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
            "cardsowupload",
          ],
          table: [],
          tab: ["tabsowinfo", "tabuploadsow"],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
            "btnsendresourcetorm",
          ],
        },
      },
      "/legal-dashboard/sow-review/rejected/inreview": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
            "cardreasoncomments",
          ],
          table: [],
          tab: [],
          button: [
            "btncustrevswitch",
            "btnmodalcancelbutton",
            "btnmodalconfirmbutton",
            "btnapproved",
            "btnrejected",
            "btndeallostbutton",
            "btnreopendealbutton",
            "btnreopenlegalbutton",
            "btnfloatingbuttons",
          ],
        },
      },
      "/legal-dashboard/sow-review/completed/completed": {
        permissions: {
          card: [
            "cardlegalprojectoverviewcard",
            "cardsowview",
            "cardlegalchecklist",
            "cardsowupload",
          ],
          table: [],
          tab: [],
          button: ["btncustrevswitch", "btnapproved", "btnrejected"],
        },
      },
    },
  },
  {
    id: 9,
    menuName: "RM Dashboard",
    url: "/rm/rm-dashboard",
    isParentRoute: true,
    permissions: {
      card: [
        "cardtotalresources",
        "cardtotalprojects",
        "cardallocated",
        "cardnotallocated",
        "cardresourceallocation",
        "cardallocationbycompetency",
        "cardallocationbyband",
        "cardallocationbylocation",
        "cardallocationbyrole",
      ],
      table: [
        "tableresourcesnotinclientprojects",
        "tableresourceswhohasaprojectenddatecomingup",
      ],
      tab: [],
      button: ["btnresourceapproachbench"],
    },
    otherRoutes: {},
  },
  {
    id: 10,
    menuName: "RM Pepole",
    url: "/rm/peoplelanding/allresources",
    isParentRoute: true,
    permissions: {
      card: [],
      table: ["tableallresourcestable"],
      tab: [
        "taballresources",
        "tabmentorsads",
        "tabinprojects",
        "tabinbench",
        "tabapproachingbench",
        "tabfulltime",
        "tabcontractors",
        "tableadership",
        "tabindia",
        "tabus",
        "tabcanada",
      ],
      button: [
        "btnaddiconinpeoplelanding",
        "btnclosebutton",
        "btnexportbutton",
        "btndownloadbutton",
        "btnapplychangesbutton",
      ],
    },
    otherRoutes: {
      "/rm/peoplelanding/mentorsads": {
        permissions: {
          card: [],
          table: ["tablemanagerstable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/inprojects": {
        permissions: {
          card: [],
          table: ["tableinprojecttable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/inbench": {
        permissions: {
          card: [],
          table: ["tableinbenchtable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/approachingbench": {
        permissions: {
          card: [],
          table: ["tableapproachbenchtable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/fulltime": {
        permissions: {
          card: [],
          table: ["tablefulltimeemployeetable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/contractors": {
        permissions: {
          card: [],
          table: ["tablecontractortable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/leadership": {
        permissions: {
          card: [],
          table: ["tableleadershiptable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/india": {
        permissions: {
          card: [],
          table: ["tableindiapeopletable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/us": {
        permissions: {
          card: [],
          table: ["tableuspeopletable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/canada": {
        permissions: {
          card: [],
          table: ["tablecanadapeopletable"],
          tab: [
            "taballresources",
            "tabmentorsads",
            "tabinprojects",
            "tabinbench",
            "tabapproachingbench",
            "tabfulltime",
            "tabcontractors",
            "tableadership",
            "tabindia",
            "tabus",
            "tabcanada",
          ],
          button: [
            "btnaddiconinpeoplelanding",
            "btnclosebutton",
            "btnexportbutton",
            "btndownloadbutton",
            "btnapplychangesbutton",
          ],
        },
      },
      "/rm/peoplelanding/add-employee": {
        permissions: {
          card: [
            "cardemployeefirstname",
            "cardemployeelastname",
            "cardemployeetype",
            "cardemployeejoiningdate",
            "cardemployeeorganization",
            "cardemployeegeography",
            "cardemployeedepartment",
            "cardemployeemanager",
            "cardemployeetitleorrole",
            "cardemployeeband",
            "cardemployeeemailaddress",
            "cardemployeetotalexperienceormonth",
            "cardemployeetotalexperienceoryear",
            "cardemployeestate",
            "cardemployeecity",
            "cardemployeephonenumber",
            "cardemployeedateofbirth",
            "cardemployeepersonalemail",
            "cardemployeeprimaryskill",
            "cardemployeesecondaryskill",
            "cardemployeedomainexperience",
            "cardemployeecertifications",
            "cardresumeuploadcontainer",
            "cardpersonalinformationform",
            "cardexperienceform",
            "cardskillandexpertiseform",
            "cardemployeedetailsform",
          ],
          table: [],
          tab: [],
          button: ["btnaddemployee", "btnaddemployeebuttoncontainer"],
        },
      },
      "/rm/peoplelanding/profile/allocated": {
        permissions: {
          card: [
            "cardoverviewcardinpeople",
            "cardstatusbarinpeople",
            "cardresumeviewcontainer",
            "cardemployeecurrentprojectdetails",
            "cardquickstats",
          ],
          table: ["tableprojecthistory", "tableinternalprojects"],
          tab: [],
          button: [
            "btnview",
            "btneditbutton",
            "btnsavebutton",
            "btncancelButton",
          ],
        },
      },
    },
  },
  {
    id: 11,
    menuName: "RM Projects",
    url: "/rm/projects/allprojects",
    isParentRoute: true,
    permissions: {
      card: [
        "cardactiveclientprojects",
        "cardcurrentclients",
        "cardprojectsexpired",
        "cardwavicleprojects",
        "cardallprojects",
      ],
      table: ['tablermProjectDashboard'],
      tab: [
        "taballprojects",
        "tabactiveprojects",
        "tabcompletedprojects",
        "tabwavicleprojects",
        "tabcomplete",
      ],
      button: ["btnexportbutton", "btndownloadIcon"],
    },
    otherRoutes: {
      "/rm/projects/activeprojects": {
        permissions: {
          card: [
            "cardallprojects",
            "cardactiveclientprojects",
            "cardcurrentclients",
            "cardprojectsexpired",
            "cardwavicleprojects",
          ],
          table: ['tablermProjectDashboard'],
          tab: [
            "taballprojects",
            "tabactiveprojects",
            "tabcompletedprojects",
            "tabwavicleprojects",
            "tabcomplete",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/projects/completedprojects": {
        permissions: {
          card: [
            "cardallprojects",
            "cardactiveclientprojects",
            "cardcurrentclients",
            "cardprojectsexpired",
            "cardwavicleprojects",
          ],
          table: ['tablermProjectDashboard'],
          tab: [
            "taballprojects",
            "tabactiveprojects",
            "tabcompletedprojects",
            "tabwavicleprojects",
            "tabcomplete",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/projects/wavicleprojects": {
        permissions: {
          card: [
            "cardactiveclientprojects",
            "cardcurrentclients",
            "cardprojectsexpired",
            "cardwavicleprojects",
          ],
          table: ['tablermProjectDashboard'],
          tab: [
            "taballprojects",
            "tabactiveprojects",
            "tabcompletedprojects",
            "tabwavicleprojects",
            "tabcomplete",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/projects/project-details/active/overview": {
        permissions: {
          card: [
            "cardprojectoverviewcard",
            "carddetailscard",
            "cardlatestfiles",
            "cardnotes",
          ],
          table: [],
          tab: ["tabresources", "taboverview"],
          button: ["btnviewsow", "btnbegindealreview", "btnsavechanges", "btncancel"],
        },
      },
      "/rm/projects/project-details/pending/overview": {
        permissions: {
          card: [
            "cardprojectoverviewcard",
            "carddetailscard",
            "cardlatestfiles",
            "cardnotes",
          ],
          table: [],
          tab: ["taboverview", "tabresources"],
          button: ["viewsow", "btncancel", "btnsavechanges"],
        },
      },
      "/rm/projects/project-details/active/resources": {
        permissions: {
          card: ["cardprojectoverviewcard"],
          table: ["tablecurrentresources"],
          tab: ["tabresources", "taboverview"],
          button: ["btnadd", "btndelete"],
        },
      },
      "/rm/projects/project-details/pending/resources": {
        permissions: {
          card: ["cardprojectoverviewcard"],
          table: ["tablecurrentresources"],
          tab: ["taboverview", "tabresources"],
          button: ["btndelete", "btnadd"],
        },
      },
      "/rm/projects/inab-details/proposal": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
      "/rm/projects/inab-details/approved": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
      "/rm/projects/inab-details/rejected": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
      "/rm/projects/inab-details/completed": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: [],
        },
      },
    },
  },
  {
    id: 12,
    menuName: "RM Resource Allocation",
    url: "/rm/resource-allocation-dashboard/new",
    isParentRoute: true,
    permissions: {
      card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
      table: ['tablermDashboard'],
      tab: [
        "tabnew",
        "tabinprogress",
        "tabreopen",
        "tabreview",
        "tabcomplete",
        "tabdemandpipeline",
      ],
      button: ["btnexportbutton", "btndownloadIcon"],
    },
    otherRoutes: {
      "/rm/resource-allocation-dashboard/inprogress": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ['tablermDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabreview",
            "tabcomplete",
            "tabdemandpipeline",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/resource-allocation-dashboard/reopen": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ['tablermDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabreview",
            "tabcomplete",
            "tabdemandpipeline",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/resource-allocation-dashboard/review": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ['tablermDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabreview",
            "tabcomplete",
            "tabdemandpipeline",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/resource-allocation-dashboard/complete": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ['tablermDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabreview",
            "tabcomplete",
            "tabdemandpipeline",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/resource-allocation-dashboard/demandpipeline": {
        permissions: {
          card: ["cardnew", "cardinprogress", "cardinreview", "cardcomplete"],
          table: ['tablermDashboard'],
          tab: [
            "tabnew",
            "tabinprogress",
            "tabreopen",
            "tabreview",
            "tabcomplete",
            "tabdemandpipeline",
          ],
          button: ["btnexportbutton", "btndownloadIcon"],
        },
      },
      "/rm/inab-resource-allocation": {
        permissions: {
          card: [
            "cardprojectnameininabcontainer",
            "cardtotalcostcontainer",
            "cardresourcesallocatedestimatedstaffingstaff",
            "cardprojecownerininabcontainer",
            "cardprojectproposalstartdateininabcontainer",
            "cardprojectbusinessorpropositionininabcontainer",
            "cardprojectdescriptionininabcontainer",
            "cardprojectsponsorsininabcontainer",
            "cardprojectproposalenddateininabcontainer",
            "cardprojectdurationinweeksininabcontainer",
            "cardprojectplatformorinfrastructurecostestimateininabcontainer",
            "cardprojectuploadproposalininabcontainer",
          ],
          table: ["tableinabstaffingtable"],
          tab: [],
          button: ["btnbeginresourceallocationbutton"],
        },
      },
      "/rm/inab-resource-allocation/resource-allocation-fact/review/inprogress":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: [
              "btnsubmitreview",
              "btnmodalconfirmbutton",
              "btnmodalcancelbutton",
            ],
          },
        },
      "/rm/inab-resource-allocation/resource-allocation-fact/review/in-review":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: [
              "btnapprove",
              "btnreject",
              "btnmodalconfirmbutton",
              "btnmodalcancelbutton",
            ],
          },
        },
      "/rm/inab-resource-allocation/resource-allocation-fact/review/reopen": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btnreturndashboard"],
        },
      },
      "/rm/inab-resource-allocation/resource-allocation-fact/reopen": {
        permissions: {
          card: ["cardprojectoverview"],
          table: [],
          tab: [],
          button: [
            "btnreview",
            "btnsave",
            "btnshowonlyavailableresources",
            "btnautorecommend",
          ],
        },
      },
      "/rm/inab-resource-allocation/resource-allocation-fact/in-progress": {
        permissions: {
          card: ["cardprojectoverview"],
          table: [],
          tab: [],
          button: [
            "btnreview",
            "btnsave",
            "btnshowonlyavailableresources",
            "btnautorecommend",
          ],
        },
      },
      "/rm/inab-resource-allocation/resource-allocation-fact/review/approved": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btncomplete"],
        },
      },
      "/rm/inab-resource-allocation/resource-allocation-fact/review/completed":
        {
          permissions: {
            card: ["cardoverviewcardinrmreview"],
            table: ["tableroledetailstable"],
            tab: [],
            button: ["btnreturndashboard"],
          },
        },
      "/rm/resource-allocation/resource-allocation-fact": {
        permissions: {
          card: ["cardprojectoverview"],
          table: [],
          tab: [],
          button: [
            "btnreview",
            "btnsave",
            "btnshowonlyavailableresources",
            "btnautorecommend",
          ],
        },
      },
      "/rm/resource-allocation/resource-allocation-fact/review/inprogress": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: [
            "btnsubmitreview",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
          ],
        },
      },
      "/rm/resource-allocation/resource-allocation-fact/review/in-review": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: [
            "btnreject",
            "btnapprove",
            "btnmodalconfirmbutton",
            "btnmodalcancelbutton",
          ],
        },
      },
      "/rm/resource-allocation/resource-allocation-fact/review/approved": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btncomplete"],
        },
      },
      "/rm/resource-allocation/resource-allocation-fact/review/completed": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btnreturndashboard"],
        },
      },
      "/rm/resource-allocation/resource-allocation-fact/review/reopen": {
        permissions: {
          card: ["cardoverviewcardinrmreview"],
          table: ["tableroledetailstable"],
          tab: [],
          button: ["btnreturndashboard"],
        },
      },
      "/rm/resource-allocation-dashboard/resource-allocation/proposal": {
        permissions: {
          card: [
            "cardprojectdescription",
            "cardoverviewcardinrmresourceallocation",
            "carddetailscontainer",
            "cardviewsow",
          ],
          table: ["tableresourcerequested"],
          tab: [],
          button: ["btnbeginra", "btnview"],
        },
      },
      "/rm/pre-staffing-overview/completed": {
        permissions: {
          card: [
            "cardprojectoverview",
            "cardprojectstatus",
            "cardprojectdescription",
            "cardadditionalinfo",
            "cardeditnotes",
            "cardeditrmnotes",
          ],
          table: ["tablepreliminarystaffing"],
          tab: [],
          button: ["btnsaveButton", "btnview", "btnopenpreliminarystaffingtable"],
        },
      },
      // "/rm/resource-allocation/resource-allocation-fact/inprogress"
      // "/rm/inab-resource-allocation/resource-allocation-fact/in-progress"
      // "/rm/inab-resource-allocation/resource-allocation-fact/inprogress"
    },
  },
];
