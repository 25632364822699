import React, { useEffect, useMemo, useState } from "react";
import useIntl from "../../../../common/lang/locale/en-US";
import Info from "../../../../assests/Info.svg";
// import { saveData } from "../../index";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { saveData } from "..";
import ProfileIcon from "../../../../assests/IconSet.svg";
import {
  FloatingButtonContainer,
  InnerButton,
} from "../../../../component/Buttons/FloatingButtons";
import ModalPopUp from "../../../../component/ModalPopUp";
import { OverViewDetailCard } from "../../../../component/cards/OverViewDetailsCard";
import TextAreaCard from "../../../../component/cards/TextAreaCard";
import { EditForm } from "../../../../component/formfields/EditForm";
import CustomTable from "../../../../component/table/CustomTable";
import Tags from "../../../../component/tags";
import SecTitle from "../../../../component/toptitlecomponent/SecTitle";
import { getParentPath, PageConfig, RM_PATH } from "../../../../configure/PathConfig";
import { API_ROUTES, apiGet, apiPost } from "../../../../configure/apiConfig";
import { ContainerWrapper, ContentWrapper } from "../../../../globalStyles";
import { OverViewDetails } from "../../../../utils/dataformatter/OverView";
import useSnackbar from "../../../../component/StatusBadge/Alert";
import { useWebsocket } from "../../../../context/websocket";
import { actionNameConfig } from "../../../../configure/menuAndSubmenuConfig";
import statusConfig from "../../../../configure/StatusConfig";
import { formatString } from "../../../../utils/dataformatter/Rawformatter";
import { getStatusText } from "../../../../component/statusbadges/StatusBadge";
import { CustomButton } from "../../../../component/Buttons/PopoverButton";
import { useSelector } from "react-redux";
import { PermittedItems } from "../../../../utils/RbacUtils";
import { useAxiosContext } from "../../../../context/axiosinstance";
import siteConfig from "../../../../common/lang/locale/en-US";

const OverviewTab = ({ overviewDetails, isRm }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [scopedNote, setScopedNote] = useState(
    overviewDetails?.scoped_notes || ""
  );
  const [rmNote, setRMNote] = useState(overviewDetails?.rm_notes || "");
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  const { ShowSnackbar, SnackbarComponent } = useSnackbar(2000);

  const { sendNotification } = useWebsocket()

  const theme = useTheme();
  const overViewData = overviewDetails;
  const { projectid } = useParams();

  const { isButtonDisabled } = useAxiosContext()


  const navigate = useNavigate();

  const handleBeginPreliminary = async () => {
    try {
      const response = await apiPost(
        API_ROUTES.UPDATE_STATUS_SCOPED_PROJECT,
        {
          projectId: projectid,
          status: "SCOPED_INPRG",
        },
        null,
        { "Socket-Action": actionNameConfig.SCOPED.BEGIN_SCOPED_PROJECT }
      );
      console.log(response);
      if (response) {
        navigate(`${getParentPath()+PageConfig["PRELIMINARY"].REVIEW}/${formatString(getStatusText(statusConfig.SCOPEDINPRG),"-")}/${projectid}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const permission = useSelector((state) => state?.permission);
  const linkWrapper = (value) => {
    if (overViewData?.prem_staffing_submitted === "No") {
      
      return (
        <CustomButton 
          style={{ color: "#95A4FC", cursor: "pointer" }} 
          type="link"
          id="BeginPreliminaryStaffing" 
          onClick={handleBeginPreliminary}>
          {value}
        </CustomButton>
      );
    } else {
      const hasAnyPermittedTable = PermittedItems({ data: [{ id : "btnopenpreliminarystaffingtable" }], component: "button", dataKey: "id", permission });
      return (
        hasAnyPermittedTable?.length > 0 &&
        <Button
          disabled={isButtonDisabled}
          variant="text"
          onClick={() => setIsModalOpen(true)}
          sx={{ textTransform: "none", padding: 0 }}
          id="btnopenpreliminarystaffingtable"
        >
          {value}
        </Button>
      );
    }
  };

  const data = OverViewDetails(overViewData, true, linkWrapper);

  let getRoleCardTableData = useCallback(async () => {
    try {
      const response = await apiGet(API_ROUTES.GET_ALL_PRELIMINARY_DETAILS, {
        projectId: projectid,
      });
      setTableData(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getRoleCardTableData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "waviclerole",
        header: "Wavicle Role",
        // minSize: 10, //min size enforced during resizing
        maxSize: 120, //max size enforced during resizing
        Cell: ({ cell }) => {
          console.log(cell.getValue().role_name);
          return cell?.getValue()?.role_name;
        },
      },
      {
        accessorKey: "location",
        header: "Location",
        maxSize: 60, //max size enforced during resizing
        editVariant: "select",
        editSelectOptions: ["Location 1", "Location 2"],
      },
      {
        accessorKey: "band_",
        header: "Band",
        // minSize: 10, //min size enforced during resizing
        maxSize: 50, //max size enforced during resizing
        editVariant: "select",
        editSelectOptions: ["Band 1", "Band 2"],
      },
      {
        accessorKey: "noofresources",
        header: "# Of Resources",
        // minSize: 10, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
      },
      {
        accessorKey: "eststartdate",
        header: "Est Start Date",
        // minSize: 10, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return moment(value).format(useIntl["staffing.date.format"]);
        },
        editVariant: "text",
      },
      {
        accessorKey: "estenddate",
        header: "Est End Date",
        // minSize: 10, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return moment(value).format(useIntl["staffing.date.format"]);
        },
        editVariant: "text",
      },
      {
        accessorKey: "resources",
        header: "Potential Team Members",
        // minSize: 10, //min size enforced during resizing
        maxSize: 100, //max size enforced during resizing
        Cell: ({ cell }) => {
          const currentValue = cell
            ?.getValue()
            ?.map((item) => item?.fullname)
            ?.join(",");
          return <Tooltip title={currentValue}>{currentValue}</Tooltip>;
        },
      },
      {
        accessorKey: "skills",
        header: "Skills Required",
        // minSize: 10, //min size enforced during resizing
        maxSize: 100, //max size enforced during resizing
        editVariant: "select",
        editSelectOptions: ["Skill 1", "Skill 2"],
        Cell: ({ cell, column, renderedCellValue, row, table }) => {
          const value = cell.getValue();
          const formattedValue = value.map((item) => item.v_skill_name);
          return Array.isArray(value) ? (
            <Tags payload={formattedValue} />
          ) : (
            value
          );
        },
      },
      {
        accessorKey: "utilization_",
        header: "Expected Util %",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return `${value}%`;
        },
        maxSize: 50, //max size enforced during resizing
        editVariant: "text",
      },
    ],
    []
  );

  const url = useLocation().pathname;
  const isCompleted = url.includes(RM_PATH+PageConfig["RM"].PRELIMINARY+"/"+formatString(siteConfig["rm.resourceallocation.completed"]));

  const additionalInfo = [
    {
      label: data?.additionalInfo[0]?.value,
      value: "test",
      subLabel: data?.additionalInfo[0]?.subLabel,
      type: overViewData?.hub_status === "SCOPED_CMPLT" || isCompleted ? "view" : "edit",
      edit: false,
    },
    {
      label: data?.additionalInfo[1]?.value,
      value: "test",
      subLabel: data?.additionalInfo[1]?.subLabel,
      type: overViewData?.hub_status === "SCOPED_CMPLT" || isCompleted  ? "view" : "edit",
      edit: false,
    },
    {
      label: data?.additionalInfo[2]?.value,
      value: "test",
      subLabel: data?.additionalInfo[2]?.subLabel,
      type: overViewData?.hub_status === "SCOPED_CMPLT" || isCompleted  ? "view" : "edit",
      edit: false,
    },
    {
      label: data?.additionalInfo[3]?.value,
      value: "test",
      subLabel: data?.additionalInfo[3]?.subLabel,
      type: overViewData?.hub_status === "SCOPED_CMPLT" || isCompleted  ? "view" : "edit",
      edit: false,
    },
    {
      label: data?.additionalInfo[4]?.value,
      value: "test",
      subLabel: data?.additionalInfo[4]?.subLabel,
      type: "view",
    },
    {
      label: data?.additionalInfo[5]?.value,
      value: "test",
      subLabel: data?.additionalInfo[5]?.subLabel,
      type: "view",
    },
    {
      label: data?.additionalInfo[6]?.value,
      value: "test",
      subLabel: data?.additionalInfo[6]?.subLabel,
      type: "view",
    },
  ];

  const [formData, setFormData] = useState(additionalInfo);

  const handleEdit = (index) => {
    console.log("clicked for edit");
    const newData = [...formData];
    newData[index].edit = true;
    setFormData(newData);
  };

  const handleSave = (index) => {
    const newData = [...formData];
    newData[index].edit = false;
    setFormData(newData);

    const saveDetails = {
      additional_info: {
        hs_deal_id: data?.hs_deal_id,
        practice_lead: newData[1].label,
        competency_lead: newData[0].label,
        engagement_manager: newData[2].label,
        delivery_manager : newData[3].label
      },
    };
    console.log("final clicked");
    saveData(saveDetails);
  };

  const handleSaveNotes = async () => {
    if (scopedNote === "<p><br></p>")
      return ShowSnackbar("Please fill notes and continue", "error");
    const notesDetails = {
      notes: {
        description: scopedNote || "",
        hs_deal_id: data?.hs_deal_id,
      },
    };
    const response = await saveData(notesDetails);
    if (response.status == 200) {
      ShowSnackbar("Notes Saved Successfully", "success");
      setShowSaveChanges(false);
    }
  };

  const handleChange = (index, newValue) => {
    // Remove numeric characters from the input value
    const filteredValue = newValue.replace(/[0-9]/g, "");

    const newData = [...formData];
    newData[index].label = filteredValue;
    setFormData(newData);
  };

  return (
    <ContentWrapper>
      {/* <Box sx={{ display: "flex", gap: 3.5, flexDirection: "column" }}> */}
      {SnackbarComponent()}
      <OverViewDetailCard
        id="cardprojectoverview"
        data={data?.projectDetail}
        status={isRm ? "SCOPED_CMPLT" : data?.status}
        title={data?.title}
        icon={ProfileIcon}
        variant="contain"
        model={isRm ? 'RM' : data?.status}
      />
      <OverViewDetailCard
        id="cardprojectstatus"
        data={data?.statusSection}
        title={data?.title2}
        statusbar
        showBorderVariant={false}
      />
      <DesAndAdditionalWrapper>
        <ContainerWrapper id={"cardprojectdescription"} padding="24px 24px 28px 24px" flex={1} gap={16}>
          <TextAreaCard
            title={useIntl["details.dealDescriptions"]}
            value={data?.dealDescription}
          />
        </ContainerWrapper>
        <ContainerWrapper id={"cardadditionalinfo"} padding="24px 24px 28px 24px" flex={1} gap={8}>
          <EditForm
            title={useIntl["addtional_information"]}
            data={formData}
            onEdit={handleEdit}
            onSave={handleSave}
            id="editAdditionalInfo"
            onChange={handleChange}
          />
        </ContainerWrapper>
      </DesAndAdditionalWrapper>
      <ContainerWrapper
        id={"cardeditnotes"}
        sx={{
          padding: theme.spacing(3),
          borderRadius: theme.spacing(2),
          width: "100%",
        }}
      >
        <TextAreaCard
          onChange={(e) => {
            setShowSaveChanges(true);
            setScopedNote(e);
          }}
          id="editnotes"
          title={useIntl["notes"]}
          titleIcon={Info}
          titleIconText="Add project notes as required"
          type={"formatter"}
          disabled={overViewData?.prem_staffing_submitted === "Yes"}
          value={scopedNote}
        />
      </ContainerWrapper>
      {overViewData?.prem_staffing_submitted === "Yes" && (
        <ContainerWrapper
          id="cardeditrmnotes"
          sx={{
            padding: theme.spacing(3),
            borderRadius: theme.spacing(2),
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <SecTitle
            title={useIntl["preliminary_staffing_notes"]}
            titleColor={theme.palette.text.primary}
          />
          <TextAreaCard
            id="editRMNotes"
            onChange={setRMNote}
            type={"formatter"}
            disabled={overViewData?.prem_staffing_submitted === "Yes"}
            value={rmNote}
          />
        </ContainerWrapper>
      )}
      <ModalPopUp
        open={isModalOpen}
        setOpen={() => {
          setIsModalOpen(false);
        }}
        isPreliminary
        header={
          <Stack direction="row" alignItems="center" p={1}>
            <RemoveRedEyeOutlinedIcon sx={{ mr: "1rem" }} />
            <Typography flexGrow={1} variant="h3">
              Preliminary Staffing
            </Typography>
            <IconButton
              sx={{
                borderRadius: "8px",
                bgcolor: "background.secondary",
                padding: "6px",
              }}
              id="closeButton"
              onClick={() => setIsModalOpen(false)}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        }
      >
        <Stack gap={3}>
          <CustomTable
            id={"tablepreliminarystaffing"}
            columns={columns}
            enableSorting={false}
            enableTopToolbar={false}
            enablePagination={false}
            data={tableData}
            enableBottomToolbar={false}
          />
        </Stack>
      </ModalPopUp>
      <FloatingButtonContainer
        id="saveButton"
        isVisible={
          !isModalOpen &&
          true &&
          overViewData?.prem_staffing_submitted === "No" &&
          showSaveChanges
        }
      >
        <InnerButton
          variant="contained"
          onClick={handleSaveNotes}
          id="saveButton"
        >
          Save Changes
        </InnerButton>
      </FloatingButtonContainer>
      {/* </Box> */}
    </ContentWrapper>
  );
};

export default OverviewTab;

const ProjectSecWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  columnGap: 28,
}));

const TagsContainerWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.primary,
  padding: "10px 0px 10px 10px",
  borderRadius: "16px",
}));

const DesAndAdditionalWrapper = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  columnGap: 28,
}));

const DesAndAdditionalContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  padding: "24px 24px 28px 24px",
  borderRadius: 16,
  gap: 16,
  width: "50%",
}));
