import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { persistor, store } from "./redux/rootReducer";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./configure/CustomTheme";
import { MsalProvider } from "@azure/msal-react";
import { PersistGate } from "redux-persist/integration/react";
import { LoaderComponent } from "./auth/PrivateRoute";
import AuthRouter from "./auth/AuthRouter";
import { RbacUtils } from "./utils/RbacUtils";
import { IntlProvider } from "react-intl";
import AppLocale from "./common/lang";
import lightTheme from "./configure/themes/lightTheme";
import { CssBaseline } from "@mui/material";
import darkTheme from "./configure/themes/darkTheme";
import OverlaySpinner from "./component/loader/OverlaySpinner";
import { WebsocketProvider } from "./context/websocket";
import AxiosInterceptor from "./context/axiosinstance";
// import { SnackbarProvider } from "notistack";

const App = ({ instance }) => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  // const [SelectedTheme] = useState(theme);

  const currentAppLocale = AppLocale.en;

  return (
    // <SnackbarProvider maxSnack={3} >
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <OverlaySpinner />
        <PersistGate loading={<LoaderComponent />} persistor={persistor}>
          <WebsocketProvider>
            <AxiosInterceptor>
              <Router>
                <IntlProvider
                  locale={currentAppLocale.locale}
                  messages={currentAppLocale.messages}
                  >
                  <AuthRouter />
                </IntlProvider>
              </Router>
            </AxiosInterceptor>
          </WebsocketProvider>
        </PersistGate>
      </ThemeProvider>
    // </SnackbarProvider>
  );
};

export default App;
