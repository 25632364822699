import { Grid, Input, Stack, Typography, styled, useTheme } from "@mui/material";
import {ReactComponent as EditIcon} from "../../assests/edit.svg";
import {ReactComponent as DisableIcon} from "../../assests/disable.svg";
import DoneIcon from "@mui/icons-material/Done";
import { formatString } from "../../utils/dataformatter/Rawformatter";
import { PermittedItems } from "../../utils/RbacUtils";
import { useSelector } from "react-redux";
import { useAxiosContext } from "../../context/axiosinstance";
export const LabelContainer = styled(Grid)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	color: "#1C1C1C",
	fontSize: "14px",
	fontWeight: 400,
	lineHeight: "20px",
	textAlign: "left",
	padding: "10px",
	borderRadius: "5px",
});

export const SubLabel = styled(Typography)(({ theme }) => ({
	textAlign: "left",
	color:theme.palette.text.secondary,
}));
export const convertMonthsToYears = (months) => {
	const years = months / 12;
  	return years % 1 === 0 ? years.toFixed(0) : years.toFixed(1);
  };
export const IconContainer = styled(Grid)({
	width: "var(--24)",
	height: "var(--24)",
	gap: "0px",
	opacity: "0px",
	display: "flex",
	alignItems: "center",
});

export const StyledIcon = styled("span")((theme) => ({
	width: "18.75px",
	height: "18.75px",
	top: "2.25px",
	left: "3px",
	gap: "0px",
	cursor: "pointer",
}));

export const EditForm = ({ id, title, data, onChange, onSave, onEdit }) => {
	const theme = useTheme();
	const permission = useSelector((state) => state?.permission);
	const { isButtonDisabled } = useAxiosContext()

	return (
		<Stack rowGap={2}>
			<Typography variant='h5'>{title}</Typography>
			<Stack rowGap={1}>
				{data.map((item, index) => (
					<LabelContainer key={index}>
						<div style={{display:"flex",flexDirection:"column"}}>
							{item.edit ? (
								<Input
								    id={`addInfo-text-${index}`}
									sx={{fontSize:theme.typography.body2}}
									value={item.label}
									disabled={item.disabled}
									onChange={(e) => onChange(index, e.target.value)}
								/>
							) : (
								<Typography variant="body2">{item.label}</Typography>
							)}
							<SubLabel variant="body3">{item.subLabel}</SubLabel>
						</div>
						<IconContainer>
							{item.type === "edit" ? (
								item.edit ? (
									<DoneIcon id={`addInfo-done-${index}`} style={{color: "rgba(161, 227, 203, 1)"}} onClick={() => onSave(index)} />
								) : (
									PermittedItems({ data : [{id:`btnaddInfoedit${formatString(item.subLabel)}`}], component: "button", dataKey: "id", permission }).length > 0 &&
									<StyledIcon id={`btnaddInfoedit${formatString(item.subLabel)}`}
										onClick={() => !isButtonDisabled && onEdit(index)}
									><EditIcon style={{color: theme.palette.text.primary}}/></StyledIcon>
								)
							) : (
								<StyledIcon id={`btnaddInfodisable${index}`}><DisableIcon style={{color: theme.palette.text.secondary, cursor: "not-allowed"}}/></StyledIcon>
							)}
						</IconContainer>
					</LabelContainer>
				))}
			</Stack>
		</Stack>
	);
};
