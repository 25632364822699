import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { PermittedItems } from "../../utils/RbacUtils";
import { useSelector } from "react-redux";
import { formatString } from "../../utils/dataformatter/Rawformatter";
import { useAxiosContext } from "../../context/axiosinstance";

// Styled Popover button
export const StyledPopoverButton = styled(Button)(({ theme, open }) => ({
    width: "28px",
    minWidth: "28px !important",
    height: "28px",
    borderRadius: theme.spacing(1), // Adjusted to make it a square
    backgroundColor: theme.palette.button.primary, // Background color
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: "0 !important",
    "&:hover": {
        backgroundColor: theme.palette.button.primary, // Change to desired color for hover state
    },
}));

export const StyledMuiPopover = styled(Popover)(({ theme }) => ({
    ".MuiPaper-root": {
        backgroundColor: "transparent",
        borderRadius: theme.spacing(1), // Adjust as needed
        boxShadow: "none", // Remove the shadow
    },
}));

export const CustomButton = ({ disabled = false, grayBackground, sx, id, type = "button", children, onClick, ...props }) => {
    const permission = useSelector((state) => state?.permission)
    const hasAnyPermittedButton = PermittedItems({ data: [{ id: "btn"+formatString(id) }], component: "button", dataKey: "id", permission});
    const theme = useTheme();
    const { isButtonDisabled } = useAxiosContext();

    console.log(isButtonDisabled)
    const buttonStyle = {
        padding: "8px 8px",
        border: "none",
        borderRadius: "8px",
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? theme.palette.button.disabled : grayBackground ? "gray" : "#54565B",
        color: disabled ? "#666" : "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: disabled ? "#ccc" : "#5776e7",
            color: disabled ? "#666" : "#fff",
        },
        ...sx,
    };

    return (
        hasAnyPermittedButton.length > 0 && (
            type === "link" ?
                <Link
                    id={"btn"+formatString(id)}
                    sx={sx}
                    onClick={isButtonDisabled ? ()=>{} : onClick}
                    {...props}
                >
                    {children}
                </Link>
                :
                <Button
                    id={"btn"+formatString(id)}
                    type={type}
                    disabled={isButtonDisabled}
                    sx={buttonStyle}
                    onClick={onClick}
                    {...props}
                >
                    {children}
                </Button>)
    );
};

const StyledPopoverComponent = ({ label, onClick, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const permission = useSelector((state) => state?.permission)
    const hasAnyPermittedButton = PermittedItems({ data: [{ id: "btn"+formatString(label) }], component: "button", dataKey: "id", permission});

    const {isButtonDisabled} = useAxiosContext()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "styled-popover" : "popover-view";

    return (
        hasAnyPermittedButton.length > 0 &&
        <div id={label}>
            {/* Three dots icon */}
            <StyledPopoverButton disable={isButtonDisabled} id={id} onClick={handleClick} open={open}>
                <Typography variant='h4' color={"text.white"}>
                    {" "}
                    <span>&#183;&#183;&#183;</span>{" "}
                </Typography>
            </StyledPopoverButton>
            <StyledMuiPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <CustomButton
                    variant='contained'
                    disableElevation
                    id={label}
                    disabled={disabled}
                    onClick={onClick}
                >
                    <Typography variant='h6' color='text.white'>
                        {label}
                    </Typography>
                </CustomButton>
            </StyledMuiPopover>
        </div>
    );
};

export default StyledPopoverComponent;
