
  const development = {
    endpointURL: {
      URL: process.env.REACT_APP_BASEURL_DEV,
    },
    websocketURL:process.env.REACT_APP_DEV_WEBSOCKET,
    uiEnv: "development",
    s3Bucket:process.env.REACT_APP_BUCKET_NAME_DEV,
    msal: {
      clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID, // REACT_APP_AAD_APP_CLIENT_ID_DEV
      tenantId: process.env.REACT_APP_AAD_APP_TENANT_ID, // REACT_APP_AAD_APP_TENANT_ID_DEV
    },
  };
  
  const stage = {
    endpointURL: {
      URL: process.env.REACT_APP_BASEURL_STAGE,
    },
    websocketURL:process.env.REACT_APP_STG_WEBSOCKET,
    uiEnv: "stage",
    s3Bucket:process.env.REACT_APP_BUCKET_NAME_STAGE,
    msal: {
      clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID, // REACT_APP_AAD_APP_CLIENT_ID_STAGE
      tenantId: process.env.REACT_APP_AAD_APP_TENANT_ID, // REACT_APP_AAD_APP_TENANT_ID_sTAGE
    },
  };
  
  const configEnvs = {
    development,
    stage,
  };
  
  const config = {
    ...configEnvs[process.env.REACT_APP_CUSTOM_NODE_ENV],
  };
  
  export default config;
  