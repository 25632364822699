// AxiosInterceptor.js

import { createContext, useContext, useEffect, useRef, useState } from 'react';
import axiosInstance from '../../configure/apiConfig';
import { useWebsocket } from '../websocket';
import { Set } from 'immutable';

const AxiosInterceptorContext = createContext({});

const AxiosInterceptor = ({ children }) => {
  const { sendNotification } = useWebsocket(); // Access WebSocket context
  const requestedActions = useRef({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [stackedAPIs, setStackAPIs] = useState([])

  console.log(stackedAPIs);

  useEffect(()=>{
   if(stackedAPIs.length > 0 ){
    if(!isButtonDisabled) return setIsButtonDisabled(true);
   }else{
    setIsButtonDisabled(false)
   }
  },[stackedAPIs])

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
        (config) => {
            const { url, headers } = config;
            let action = null;

            setStackAPIs(pre => [...new Set([...pre,url])]);

        
            if (headers && headers["Socket-Action"]) {
              console.log("headers.action", headers["Socket-Action"]);
              action = headers["Socket-Action"];
        
              // Save the action associated with the URL
              requestedActions.current[url] = action;
        
              // Remove the action from headers
              delete headers["Socket-Action"];
            }
        
            // Return the modified config
            return config; // No need to re-assign headers and url since you already modified config directly
          },
          (error) => {
            return Promise.reject(error);
          }
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
        (response) => {
            const { status, config } = response;
      
            setStackAPIs(pre => [...pre.filter(url => url !== config.url)]);
            if (status === 200) {
              console.log("Response for deal review API", config);
              // call a function here
              if (requestedActions.current[config.url]) {
                console.log("Response for action API", response);
                const action = requestedActions.current[config.url];
                console.log(action);
                try {
                  if(action){
                    sendNotification(action);
                  }
                } catch (error) {
                  console.log(error)
                }
                delete requestedActions.current[config.url];
              }
            }
            return response;
          },
          (error) => {
            console.log(error)
            if(error.code === "ERR_CANCELED") return Promise.reject(error);;
            setStackAPIs(pre => [...pre.filter(url => url !== error.config.url)]);
            return Promise.reject(error);
          }
    );

    // Clean up interceptors on unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [sendNotification]);

  return (
    <AxiosInterceptorContext.Provider value={{isButtonDisabled}}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};

export { axiosInstance };

export const useAxiosContext = () => useContext(AxiosInterceptorContext);

export default AxiosInterceptor;
